<template>
  <div class="form-container">
    <div style="flex:1">
      <div class="input-label">카드 번호</div>
      <input type="text" @input="e => numDash(e.target.value, e)" placeholder="'-' 생략하고 입력" maxlength="19"
             :value="num_dash" id="card-num-input" onpaste="javascript:return false;">
      <div class="err" v-if="err.card_num.err">{{ err.card_num.text }}</div>
      <div class="input-label">유효기간</div>
      <div class="flex">
        <input type="text" v-model="value.ex_month" placeholder="월" class="input-date" maxlength="2" id="ex-month"  autocomplete="new-password" aria-hidden="true">
        <input type="text" v-model="value.ex_year" placeholder="년" class="input-date" maxlength="2" id="ex-year" autocomplete="new-password" aria-hidden="true">
      </div>
      <div class="err" v-if="err.ex.err">{{ err.ex.text }}</div>

      <div class="input-label">카드 비밀번호 앞 2자리</div>
      <div class="flex-align">
        <input type="password" v-model="value.pw" class="input-pw" maxlength="2" id="pw" autocomplete="new-password">
        <div class="h5">••</div>
      </div>
      <div class="err" v-if="err.pw.err">{{ err.pw.text }}</div>
      <div class="input-label">생년월일 6자리(개인) / 사업자번호 10자리(법인)</div>
      <input type="text" v-model="value.birth" maxlength="10">
      <div class="err" v-if="err.birth.err">{{ err.birth.text }}</div>
    </div>

    <div v-if="isModal" class="flex-between" style="padding-top: 20px">
      <button class="button is-gray"
              style="flex:1;margin-right:8px" @click="$emit('cancel')">취소</button>
      <button class="button is-primary"
              style="flex:1; margin-left: 8px" @click="clickReg">카드 등록하기</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardInputForm",
    props: {
      isModal: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object
      }
    },
    data() {
      return {
        num_dash: '',
        err: {
          card_num: {
            text: '카드 번호를 잘못 입력하셨습니다',
            err: false
          },
          ex: {
            text: '유효기간을 잘못 입력하셨습니다.',
            err: false
          },
          pw: {
            text: '비밀번호를 잘못 입력하셨습니다.',
            err: false
          },
          birth: {
            text: '생년월일/사업자번호를 잘못 입력하셨습니다.',
            err: false
          }
        }
      }
    },
    methods: {
      numDash(val, ev) {
        if(ev.inputType.indexOf('delete')>-1) {
          if(val.length===4) {
            val = val.slice(0, -1);
          }
          else if(val.length===9) {
            val = val.slice(0, -1);
          }
          else if(val.length===14) {
            val = val.slice(0, -1);
          }
        }
        else {
          if(isNaN(Number(val.charAt(val.length-1)))) {
            document.getElementById('card-num-input').value = document.getElementById('card-num-input').value.slice(0,-1);
            return;
          }

          if(val.length===4) {
            val = val + '-';
          }
          else if(val.length===9) {
            val = val + '-';
          }
          else if(val.length===14) {
            val = val + '-';
          }
        }

        this.num_dash = val;
        this.value.card_num = val.replaceAll('-','');
      },
      clickReg() {
        if(this.validate()) {
          this.$emit('enrollCard');
        } else {
          this.toast('카드 정보를 제대로 입력해주세요.');
        }
      },
      validate() {
        let valid = true;
        Object.keys(this.value).forEach(key=>{
          if(this.value[key] === '') {
            valid = false;
          }
        });

        return valid;
      },
    }
  }
</script>


<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .form-container
    display flex
    flex-direction column
    height 100%

  .input-label
    font-size 14px
    font-weight 500
    margin 24px 0 6px 0

  .input-date
    width 80px
    margin-right 16px

  .input-pw
    width 80px
    margin-right 8px
    font-size 23px !important
    padding 6px 16px !important

  .err
    font-size 13px
    color $error
    margin-top 4px

</style>
